// src/components/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = ({ setAuthenticated }) => {
  const [pin, setPin] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    if (pin === '559900') {  // Replace '1234' with your desired PIN
      setAuthenticated(true);
      localStorage.setItem('isAuthenticated', 'true'); // Save authentication state
      navigate('/');
    } else {
      alert('Invalid PIN');
    }
  };

  return (
    <div className="container mt-5">
      <h2>Login</h2>
      <input
        type="password"
        value={pin}
        onChange={(e) => setPin(e.target.value)}
        className="form-control mb-3"
        placeholder="Enter PIN"
      />
      <button onClick={handleLogin} className="btn btn-primary">
        Login
      </button>
    </div>
  );
};

export default Login;
