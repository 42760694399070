import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './StudentList.css';
import { useNavigate } from 'react-router-dom';

const ClassList = () => {
  const [classes, setClasses] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
 
  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    
    const filtered = classes.filter(classs => 
        classs.Class_Name.toLowerCase().includes(query)
    );
    setFilteredClasses(filtered);
  };

  const GoToStudentList = (e) => {
    const classId = e.target.getAttribute('data-classid');
    const classname = e.target.getAttribute('data-classname');
    navigate('/studentlist', { state : { classId, classname}}); 

  };

  useEffect(() => {
    const fetchClassList = async () => {
      try {
        const apiUrl = localStorage.getItem('apiUrl');
        const response = await axios.get(`${apiUrl}/StudentClass/List`);
        setClasses(response.data);
        setFilteredClasses(response.data); // Initially, all students are displayed
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false); // Set loading to false even if there's an error
      }
    };

    fetchClassList();
  }, []);

  if (loading) {
    return <div className="text-center mt-5"><span className="spinner-border spinner-border-sm" role="status"></span></div>;
  }

  return (
    <div className="container mt-3">
      <div className="dashboard-header text-center mb-4">
        <h2 className="text-primary"><i className="bi bi-people-fill text-primary"></i> Select Class</h2>
      </div>
      <div className="mb-3">
        <div className="input-group mb-3">
          <span className="input-group-text bg-primary text-white" id="basic-addon1" style={{ display: 'block' }}>
            <i className="bi bi-search"></i>
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="Search by class name"
            aria-label="Search"
            aria-describedby="basic-addon1"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      {filteredClasses.length > 0 ? (
        filteredClasses.map(classs => (
          <div key={classs.ID} className="mb-3" style={{ maxWidth: '100%' }}>
            <ul class="list-group">
  <             li class="list-group-item" style={{ cursor: 'pointer'}} data-classid={classs.ID} data-classname={classs.Class_Name} onClick={GoToStudentList}> {classs.Class_Name}</li>
            </ul>
           </div>
        ))
      ) : (
        <p>No class(es) found.</p>
      )}
    </div>
  );
};

export default ClassList;
