import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './StudentList.css';
import { useLocation } from 'react-router-dom';

const StudentList = () => {
  const [students, setStudents] = useState([]);
  
  const location = useLocation();
  const classId = location.state?.classId;
  const classname = location.state?.classname;

  const [filteredStudents, setFilteredStudents] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    
    const filtered = students.filter(student => 
      student.StudentName.toLowerCase().includes(query) || 
      student.FatherName.toLowerCase().includes(query) ||
      student.ClassName.toLowerCase().includes(query)
    );
    setFilteredStudents(filtered);
  };

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const apiUrl = localStorage.getItem('apiUrl');
        const response = await axios.get(`${apiUrl}/GetStudentList/List?classId=${classId}`);
        setStudents(response.data);
        setFilteredStudents(response.data); // Initially, all students are displayed
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error('Error fetching student data:', error);
        setLoading(false); // Set loading to false even if there's an error
      }
    };

    fetchStudents();
  }, []);

  if (loading) {
    return <div className="text-center mt-5"><span className="spinner-border spinner-border-sm" role="status"></span></div>;
  }

  return (
    <div className="container mt-3">
      <div className="dashboard-header text-center mb-4">
        <h2 className="text-primary"><i className="bi bi-people-fill text-primary"></i> Registered Students </h2>
        <span className="badge bg-success">{classname}</span>
      </div>
      <div className="mb-3">
        <div className="input-group mb-3">
          <span className="input-group-text bg-primary text-white" id="basic-addon1" style={{ display: 'block' }}>
            <i className="bi bi-search"></i>
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="Search by student name, father name, or class"
            aria-label="Search"
            aria-describedby="basic-addon1"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      {filteredStudents.length > 0 ? (
        filteredStudents.map(student => (
          <div key={student.Reg_Id} className="card border-dark mb-3" style={{ maxWidth: '100%' }}>
            <div className="card-header bg-secondary bg-gradient text-white text-bold">
              <i className="bi bi-person-circle"></i> {student.Reg_Id} - {student.StudentName}
            </div>
            <div className="card-body text-dark">
  <h5 className="card-title"><i className="bi bi-star text-success"></i> {student.ClassName}</h5>
  <div className="card-body text-dark">
  <div className="row">
    <div className="col-1 border-end border-primary">
      <i className="bi bi-person-badge text-primary"></i>
    </div>
    <div className="col-11">
      <strong>Father's Name:</strong> {student.FatherName}
    </div>
  </div>
  <div className="row mt-2">
    <div className="col-1 border-end border-danger">
      <i className="bi bi-person-lines-fill text-danger"></i>
    </div>
    <div className="col-11">
      <strong>Mother's Name:</strong> {student.MotherName}
    </div>
  </div>
  <div className="row mt-2">
    <div className="col-1 border-end border-warning">
      <i className="bi bi-telephone-fill text-warning"></i>
    </div>
    <div className="col-11">
      <strong>Contacts:</strong> <a href={`tel:${student.FatherContact}`}>{student.FatherContact}</a> / <a href={`tel:${student.MotherContact}`}>{student.MotherContact}</a>
    </div>
  </div>
  <div className="row mt-2">
    <div className="col-1 border-end border-success">
      <i className="bi bi-calendar text-success"></i>
    </div>
    <div className="col-11">
      <strong>Date of Birth:</strong> {new Date(student.DateOfBirth).toLocaleDateString()}
    </div>
  </div>
  <div className="row mt-2">
    <div className="col-1 border-end border-info">
      <i className="bi bi-calendar2-check text-info"></i>
    </div>
    <div className="col-11">
      <strong>Registration Date:</strong> {new Date(student.RegDate).toLocaleDateString()}
    </div>
  </div>
  <div className="row mt-2">
    <div className="col-1 border-end border-warning">
      <i className="bi bi-wallet text-warning"></i>
    </div>
    <div className="col-11">
      <strong>Tuition Fee:</strong> {student.TuitionFee}
    </div>
  </div>
  <div className="row mt-2">
    <div className="col-1 border-end border-success">
      <i className="bi bi-house-door text-success"></i>
    </div>
    <div className="col-11">
      <strong>Address:</strong> {student.HomeAddress}
    </div>
  </div>
</div>

</div>

          </div>
        ))
      ) : (
        <p>No students found.</p>
      )}
    </div>
  );
};

export default StudentList;
