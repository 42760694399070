import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import BranchSelection from './components/BranchSelection';
import Dashboard from './components/Dashboard';
import NavBar from './components/NavBar';
import StudentList from './components/StudentList';
import ClassList from './components/ClassList';
import Login from './components/Login';
import './App.css';

function App() {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [branchName, setBranchName] = useState('');
  const [apiUrl, setApiUrl] = useState('');

  useEffect(() => {
    const authStatus = localStorage.getItem('isAuthenticated');
    if (authStatus) {
      setAuthenticated(true);
    }
  }, []);

  return (
    <Router>
      {isAuthenticated && <NavBar />}
      <Routes>
        <Route
          path="/login"
          element={<Login setAuthenticated={setAuthenticated} />}
        />
        <Route
          path="/branchselection"
          element={isAuthenticated ? <BranchSelection setBranchName={setBranchName} setApiUrl={setApiUrl} /> : <Navigate to="/login" />}
        />
        <Route
          path="/dashboard"
          element={isAuthenticated ? <Dashboard branchName={branchName} apiUrl={apiUrl} /> : <Navigate to="/login" />}
        />
        <Route
          path="/classlist"
          element={isAuthenticated ? <ClassList /> : <Navigate to="/login" />}
        />
        <Route
          path="/studentlist"
          element={isAuthenticated ? <StudentList /> : <Navigate to="/login" />}
        />
        <Route
          path="/"
          element={isAuthenticated ? <BranchSelection setBranchName={setBranchName} setApiUrl={setApiUrl} /> : <Navigate to="/login" />}
        />
      </Routes>
    </Router>
  );
}

export default App;
