import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Dashboard.css';

const apiUrl = localStorage.getItem('apiUrl');

const Dashboard = ({ branchName, apiUrl }) => {
  const [counts, setCounts] = useState({
    activeStudents: 0,
    todayFee: 0,
    monthlyFee: 0,
    todayAttendance: 0,
  });

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const url = `${apiUrl}/Dashboard/Counts/?date=${new Date().toISOString().slice(0, 10)}`;
        console.log('Fetching data from:', url);
        const response = await axios.get(url);
        console.log(response);
        setCounts({
          StudentCount: response.data.StudentCount,
          DailyFeeCollection: response.data.DailyFeeCollection,
          MonthlyFeeCollection: response.data.MonthlyFeeCollection,
          AttendanceRatio: response.data.AttendanceRatio
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (apiUrl) {
      fetchCounts();
    }
  }, [apiUrl]);

  return (
    <div className="container mt-3">
      <div className="dashboard-header text-center mb-4">
        <h1 className="text-success"><i class="bi bi-house-gear text-success"></i> Dashboard</h1>
        <span className="badge bg-primary">{branchName} </span>
      </div>
      <div className="row">
        <div className="col-12 mb-3">
          <div className="card text-white bg-success">
            <div className="card-body d-flex align-items-center">
              <i className="bi bi-people-fill display-4 me-3"></i>
              <div>
                <h5 className="card-title">Active Students</h5>
                <h2>
                  {counts.StudentCount !== null && counts.StudentCount !== undefined 
                    ? counts.StudentCount 
                    : <span className="spinner-border spinner-border-sm" role="status"></span>}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mb-3">
          <div className="card text-white bg-info">
            <div className="card-body d-flex align-items-center">
              <i className="bi bi-currency-dollar display-4 me-3"></i>
              <div>
                <h5 className="card-title">Today's Fee</h5>
                <h2>
                  {counts.DailyFeeCollection !== null && counts.DailyFeeCollection !== undefined 
                    ? counts.DailyFeeCollection 
                    : <span className="spinner-border spinner-border-sm" role="status"></span>}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mb-3">
          <div className="card text-white bg-warning">
            <div className="card-body d-flex align-items-center">
              <i className="bi bi-calendar2-check display-4 me-3"></i>
              <div>
                <h5 className="card-title">Monthly Fee</h5>
                <h2>
                  {counts.MonthlyFeeCollection !== null && counts.MonthlyFeeCollection !== undefined 
                    ? counts.MonthlyFeeCollection 
                    : <span className="spinner-border spinner-border-sm" role="status"></span>}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="card text-white bg-danger">
            <div className="card-body d-flex align-items-center">
              <i className="bi bi-check2-square display-4 me-3"></i>
              <div>
                <h5 className="card-title">Today's Attendance</h5>
                <h2>
                  {counts.AttendanceRatio !== null && counts.AttendanceRatio !== undefined 
                    ? counts.AttendanceRatio 
                    : <span className="spinner-border spinner-border-sm" role="status"></span>}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
