// src/components/NavBar.js
import React, { useState } from 'react';
import './NavBar.css';
import logo from '../img/logo.png'
import config from '../config'
import { useNavigate } from 'react-router-dom';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const GoToBranchSelection = () => {
    setIsOpen(false);
    navigate('/branchselection'); 
  };

  const GoToStudentList = () => {
    setIsOpen(false);
    navigate('/studentlist'); 
  };

  const GoToClassList = () => {
    setIsOpen(false);
    navigate('/classlist'); 
  };

  return (
    <nav className="nav-bar">
      <div className="logo"> <img src={logo} className='logo-img' /> {config.AppName}</div>
      <div className={`menu-icon ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>
      <ul className={`nav-items ${isOpen ? 'open' : ''}`}>
        <li className="nav-item"><a href="#" onClick={GoToBranchSelection}> <i className="bi bi-toggles"></i> Switch Branch</a></li>
        <li className="nav-item"><a href="#" onClick={GoToClassList}> <i className="bi bi-person-lines-fill"></i> Active Students</a></li>
        {/* <li className="nav-item"><a href="#" onClick={GoToStudentList}> <i className="bi bi-person-lines-fill"></i> Student List</a></li> */}
      </ul>
    </nav>
  );
};

export default NavBar;
